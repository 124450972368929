import React from "react"
import {Link} from "gatsby"

const BlogCards = data => {
//   console.log(data.data.categories)
  var sentence = data.data.excerpt
  var des=sentence.split("</p>")
    return (
      
        <div className="card border-0 cosmoally-card  col-4" style={{ maxWidth:'calc(33.33% - 8px)',minWidth:'26%',margin:'4px' }}>

            <Link to={data.data.slug+"/"}>
                <img className="card-img-top" src={data.data.featured_media.localFile.childImageSharp.resolutions.src} alt={''}/>
            </Link>
        <div className="card-body">

            <Link to={data.data.slug+"/"}>
                <h5 className="card-title" dangerouslySetInnerHTML={{__html:data.data.title}} />
            </Link>
            <p className="card-text">
                <small>{data.data.date}</small><small>
                {data.data.categories.map(cat => (
                    <>
                       &nbsp;&nbsp;|&nbsp;&nbsp;  <a href={"/category/"+cat.name.toLowerCase()+"/"}>{cat.name}</a>
                    </>
                ))}
            </small>
            </p>
            <div dangerouslySetInnerHTML={{__html: des[0]+"</p>"}}/>
        </div>
    </div> 
    )
}
export default BlogCards
