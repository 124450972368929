import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import BlogCards from "../components/BlogCards"
import MobileBlogCards from "../components/MobileBlogCards";
import images from "../components/images";
import { Helmet } from "react-helmet"
import ReactHtmlParser from "react-html-parser";

export default ({data}) => {

    let c = data.cat.edges[0].node;

    let yoast_head = c.yoast_head.replace(/http:\/\/cosmoally.net/ig, 'https:\/\/cosmoally.com') || c.yoast_head
    let temp = yoast_head.match(/<script.+?<\/script>/g)[0] || ''
    let schema = temp !== undefined && temp !== null && temp.length > 70 ? temp.substring(62, temp.length-9) : {}

    return (
        <Layout>

            <Helmet>

                <html lang="en" />
                <title>{c.name} | Cosmoally</title>
                <meta name="description" content={c.name+" | Cosmoally"}/>

                { ReactHtmlParser(yoast_head) }

                <script type="application/ld+json">
                    {schema}
                </script>

            </Helmet>
            <div>
                <img src={images.blogBanner}
                     className="Cosmetic Registration in India: What is the difference between Legal and Actual Manufacturer?"/>
            </div>

            <div className="container-fluid pb-4" style={{backgroundColor: 'rgb(245 245 245)'}}>
                <div className="row">
                    <div className="col-12">
                        <div className="section-title2 pt-5"><h1 className="section-title-heading fsm-theme-heading">Alcoholic Beverages</h1></div>
                    </div>
                </div>
            </div>

            <div className="container-fluid desktop_view_layout  d-none d-md-block pb-5"
                 style={{backgroundColor: 'rgb(245 245 245)'}}>


                <div className="container">
                    <br/>
                    <div className={'row'}>
                        <div className={'col'}>
                            <div>
                                <div className="card-group row">
                                    {data.post.edges.reverse().map(({node}) => (
                                        <BlogCards data={node}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="mobile_view_layout  d-block d-md-none"
                 style={{backgroundColor: 'rgb(220 220 220)', width: '100%'}}>
                <div className={'container'}>
                    {data.post.edges.reverse().map(({node}) => (
                        <MobileBlogCards data={node}/>
                    ))}
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query myQuery1($slug: String!) {
      cat:allWordpressCategory(filter: {slug: {eq: $slug}}) {
        edges {
          node {
            name
            yoast_head
          }
        }
      }
    post: allWordpressPost(sort: {fields: [date]}, filter: {categories: {elemMatch: {slug: {eq: $slug}}}}) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "DD-MMM-YYYY")
          categories{
            name
          }
           featured_media {
                localFile {
                  childImageSharp {
                    resolutions(width: 768, height: 768) {
                      src
                      height
                      width
                    }
                }
              }
            }
        }
      }
    }
  }`
