import React from "react"
import {Link} from "gatsby"

const MobileBlogCards = data => {

  var sentence = data.data.excerpt
  var des=sentence.split("</p>")
    return (
      
        <div className="row cosmoally-card-mobile">
                <div className={'col-3 pl-0 pr-0'}>
                    <div style={{marginBottom: '10px'}}>
                        <Link to={data.data.slug+"/"}>
                            <img src={data.data.featured_media.localFile.childImageSharp.resolutions.src} alt={''}
                                 className={'wp-post-img'}/>
                        </Link>

                    </div>
                </div>
                <div className={'col-9'}>
                    <small>{data.data.date}</small>
                    <small>{data.data.categories.map(cat => (
                        <>
                            &nbsp;&nbsp;|&nbsp;&nbsp; <a href={"/category/"+cat.name.toLowerCase()+"/"}>{cat.name}</a>
                        </>
                    ))}
                    </small>
                    <Link to={data.data.slug+"/"}>
                        <h5 style={{color: '#222',fontSize:'16px'}}  dangerouslySetInnerHTML={{__html:data.data.title}} />
                    </Link>
                    <div style={{ fontSize:'14px' }} dangerouslySetInnerHTML={{__html: des[0].substring(0,80)+"...</p>"}}/>
                </div>
            </div>

    )
}
export default MobileBlogCards
